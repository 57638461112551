export const environment = {
  production: true,
  baseUrl: 'https://stacksjar.com/blogbackend/api/',
  userImages: 'https://stacksjar.com/blogbackend/public/storage/',  
  blogImages: 'https://stacksjar.com/blogbackend/public/images/',
  domain: 'https://stacksjar.com',
  memesApi: 'https://meme-api.com/gimme',
  quotesApi: 'https://api.quotable.io/random',
  disableLogs: true  
};
