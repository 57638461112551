import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Inject, InjectionToken, PLATFORM_ID } from '@angular/core';

@Directive({
  selector: '[CheckAdmin]'
})
export class CheckadminDirective {

  constructor(@Inject(PLATFORM_ID) private platformId: object, private elementRef: ElementRef) { }

  ngOnInit() {
    let status:Boolean = false;

    if (isPlatformBrowser(this.platformId)) {
      if(localStorage.getItem('userDetails')) {
        let userDetails = JSON.parse(localStorage.getItem('userDetails'))
        if(userDetails.userType == "ADMIN") {
          status = true;
        }
      }
    }
    
    if(!status) {
      // this.elementRef.nativeElement.hidden = true;
      this.elementRef.nativeElement.remove();
    }
    else {
      this.elementRef.nativeElement.hidden = false;
    }
  }

}
