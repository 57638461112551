import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WidgetsRoutingModule } from './widgets-routing.module';
import { TrendingpostsComponent } from './trendingposts/trendingposts.component';
import { TagswidgetComponent } from './tagswidget/tagswidget.component';
import { SubscribewidgetComponent } from './subscribewidget/subscribewidget.component';
import { FormsModule } from '@angular/forms';
import { AffiliatewidgetComponent } from './affiliatewidget/affiliatewidget.component';


@NgModule({
  declarations: [
    TrendingpostsComponent,
    TagswidgetComponent,
    SubscribewidgetComponent,
    AffiliatewidgetComponent
  ],
  imports: [
    CommonModule,
    WidgetsRoutingModule,
    FormsModule
  ],
  exports: [
    TrendingpostsComponent,
    TagswidgetComponent,
    SubscribewidgetComponent,
    AffiliatewidgetComponent
  ]
})
export class WidgetsModule { }
