import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import { BlogserviceService } from '../services/blogservice.service';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { environment } from 'src/environments/environment';
import { SetmetadataService } from '../services/setmetadata.service';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { ads } from 'src/assets/data/adsData';
import { GagtmService } from '../services/gagtm.service';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.less']
})
export class ListingComponent implements OnInit {
  listingAssociateProd: String;
  svgUrl = 'assets/images/character-bg.png';
  category: String;
  limit: number = 14;
  page: number = 1;
  lastAccessedPage: number = 0;
  blogs: any[] = [];
  trendingBlogs: any[] = [];
  recentBlogs: any[] = [];
  totalBlogs: number;
  localStorage: any;
  serverImgUrl = environment.blogImages;
  authorImg = environment.userImages;
  @ViewChild('listingstart') listingstart;
  @ViewChild('paginator') paginator;
  showYoutube: Boolean = false;
  showAds: Boolean = false;
  isGridView: Boolean = false;
  loaderItems: number[] = [0];
  selectedSideContent: string = 'trending';
  affiliateWidgetRightOne: any;

  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: object, private metadataService: SetmetadataService, public loadingBar: LoadingBarService, private blogService: BlogserviceService, private router: Router, private activeRoute: ActivatedRoute, private gagtm: GagtmService) {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage = localStorage;
      this.listingAssociateProd = ads.listingAssociateProd;
      this.setPaginationData();
    }
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(res => {
      if(res && res['categoryID']) {
        this.category = res['categoryID'];
        this.setLinkTag(this.category);
        this.resetLastAccessedPageData()
      }
      this.getBlogs();
      this.getAffiliateData();
    })
  }

  getAffiliateData() {
    this.blogService.getAffiliateProducts().subscribe(res => {
      const prodId = res['widgets']['listingRightOne'];
      this.affiliateWidgetRightOne = res['products'].find(p => p.id === prodId);
      if(res.dataSource === "json") {
        this.blogService.setAffiliateDataToLocalStorage(res);
      }
    });
  }

  switchSideContent(val: string) {
    this.selectedSideContent = val;
  }

  resetLastAccessedPageData() {
    this.page = 1;
    this.lastAccessedPage = ((this.page - 1) * this.limit);
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.setItem('pageNo', this.page);
    }
  }

  toggleIconClick(value:boolean) {
    this.isGridView = value;
    this.toggleView();
  }

  setPaginationData() {
    this.isGridView = this.localStorage.getItem('isGridView') === 'true';
    const pageNo = this.localStorage.getItem('pageNo');
    if(pageNo) {
      this.page = parseInt(pageNo);
    }
    this.lastAccessedPage = ((this.page - 1) * this.limit);
    this.setLoaders();
  }

  setLoaders() {
    if(this.isGridView) {
      this.loaderItems.push(1);
    }
    else {
      this.loaderItems.splice(1);
    }
  }

  toggleView() {
    this.localStorage.setItem('isGridView', this.isGridView.toString());
    this.setLoaders();

    // gagtm event tracking
    const data = {
      event: 'ViewToggler_Click',
      value: this.isGridView ? 'GridView' : 'ListView'
    }
    this.gagtm.triggerEvent(data);  
  }

  getBlogs() {
    let obj = {
      "limit" : this.limit,
      "page" : this.page
    }

    this.blogService.getBlogs(obj, this.category).subscribe(res =>{
      if(res) {
        this.blogs = res['blogs'];
        this.trendingBlogs = res['trendingBlogs'];
        this.recentBlogs = res['recentBlogs'];
        this.totalBlogs = res['totalBlogs'];

        let data = {
          title : `Top articles on ${this.category ? this.category : 'Trending web stacks'}`,
          url: `${environment.domain}${this.router.url}`,
        }

        // set description if on category page
        if(this.category) {
          data['description'] = `Learn through High Quality Resources and get latest articles on ${this.category ? this.category : 'Trending web stacks'}`;
        }

        setTimeout(() => {
          this.showYoutube = true;
        }, 15000);

        this.setMetaTags(data);

        if (isPlatformBrowser(this.platformId)) {
          this.showAds = true;
        }
      }
    })
  }

  routeToDetails(data) {
    this.router.navigate(['/post', data.blog_url]);
  }

  pageChanged(event) {
    this.page = event.page + 1;
    this.showAds = false;
    this.localStorage.setItem('pageNo', this.page);
    this.getBlogs();
    const top = this.listingstart?.nativeElement?.offsetTop;
    window.scrollTo(0, top ? top : 0);
  }

  goToHome() {
    this.router.navigate(['/']);
  }

  setMetaTags(meta) {
    this.metadataService.setMetaTags(meta);
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      const item: any = document.querySelector('link[rel="canonical"]');
      
      if(item) {
        document.head.removeChild(item);
      }
    }
  }

  setLinkTag(string) {
    const item: any = this.document.querySelector('link[rel="canonical"]');

    if(item) {
      item.href = `https://stacksjar.com/category/${string}`;
    }
    else {
      const link = this.renderer.createElement('link');
      link.rel = 'canonical';
      link.href = `https://stacksjar.com/category/${string}`;
      
      this.renderer.appendChild(this.document.head, link);
    }
  }
}
