import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InjectstylesService {
  

  constructor(@Inject(DOCUMENT) private document: Document) { }

  loadStyle(data: Array<String>) {
    const head = this.document.getElementsByTagName('head')[0];

    let func: any = "this.media='all'";

    if(data && data.length > 0) {
      data.forEach(item => {
        let style = this.document.createElement('link');
        // style.id = 'style';
        style.rel = 'stylesheet';
        style.href = `${item}`;
        style.type = `text/css`;
        style.media = `print`;
        style.onload = func;
      
        head.appendChild(style);
      });
    }
  }

  loadScript(data: Array<String>) {
    const head = this.document.getElementsByTagName('head')[0];

    let func: any = "this.media='all'";

    if(data && data.length > 0) {
      data.forEach(item => {
        const js = this.document.createElement('script');

        js.src = `${item}`;
        js.type = 'text/javascript';
        js.crossOrigin
        js.defer

        head.appendChild(js);
      });
    }
  }

}


// media="print" onload="this.media='all'"