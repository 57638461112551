import { Component } from '@angular/core';
import { BlogserviceService } from 'src/app/services/blogservice.service';

@Component({
  selector: 'app-subscribewidget',
  templateUrl: './subscribewidget.component.html',
  styleUrls: ['./subscribewidget.component.less']
})
export class SubscribewidgetComponent {
  emailId: string;

  constructor(private blogService: BlogserviceService) {}

  subscribeNewsletter(email) {
    email.isSubmitted = true;
    const emailCheck = this.checkEmail();
    if(this.emailId && emailCheck) {
      email.loading = true;
      let data = {
        'email': this.emailId
      } 
      
      this.blogService.subscribeToNewsletter(data).subscribe(res => {
        if(res) {
          console.log(res);
          if(res['statusCode'] == 200) {
            
            setTimeout(() => {
              email.loading = false;
              email.completed = true;
              email.reset();
              email.completed = false;
              email.isSubmitted = false;
            }, 1000);
          }
        }
      },
      err => {
        if(err) {
          email.completed = false;
          email.loading = false;
        }
      })
    }
    else {
      email.isInvalid = true;
    } 
  }

  checkEmail() {
    const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(String(this.emailId).toLowerCase());
  }

  emailInputs(email) {
    if(email.isSubmitted) {
    const res = this.checkEmail();  
      if(res) {
        email.isInvalid = false;
      }
      else {
        email.isInvalid = true;
      }
    }
  }

}
