import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-trendingposts',
  templateUrl: './trendingposts.component.html',
  styleUrls: ['./trendingposts.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class TrendingpostsComponent {
  @Input('selectedSideContentData') selectedSideContent;
  @Input('trendingBlogsData') trendingBlogs;
  @Input('recentBlogsData') recentBlogs;
  @Output() selectedSideContentCallback = new EventEmitter<string>();


  callSwitchSideContent(value: string) {
    this.selectedSideContentCallback.emit(value);
  }
}
