<div class="widget-header">
  <h3>Tags</h3>
</div>
<div class="tags-wrapper">
  <a class="tags-item" *ngFor="let tag of tagsData" routerLink="/tags/{{tag.url}}">
    <span class="tag"
      [ngStyle]="{'background': tag.bgColor ? tag.bgColor : '#f5f5f5', 'color' : tag.color ? tag.color : '#777'}">
      <i _ngcontent-serverapp-c85="" class="fas fa-hashtag"></i>{{tag.name}}
    </span>
  </a>
</div>