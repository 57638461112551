import { Component, Inject, Injectable, InjectionToken, isDevMode, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
// import { NgcCookieConsentService } from 'ngx-cookieconsent';
// import { Subscription } from 'rxjs';

import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

import { InjectstylesService } from './services/injectstyles.service';
import { environment } from 'src/environments/environment';
import { LoggerService } from './services/logger.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})

export class AppComponent {
  title = 'StacksJar';
  localStorage: any;
  isDevToolsOpen: boolean = false;
  scripts: Array<String> = [
    'jquery.slim.js',
    'popper.js',
    'bootstrap.js',
  ]
  stylesheets: Array<String> = [
    // 'bootstrap.css',
    // 'cookieconsent.min.css',
    // 'skeletonloader.css',
    'quill.bubble.css',
    'quill.snow.css',
    'deeppurple-amber.css',
    'primeng.min.css',
    'nova-light-theme.css',
    'primeicons.css',
    // 'notiflix-angular-1.1.0.css',
  ];
  
  //keep refs to subscriptions to be able to unsubscribe later
  // private popupOpenSubscription: Subscription;
  // private popupCloseSubscription: Subscription;
  // private initializeSubscription: Subscription;
  // private statusChangeSubscription: Subscription;
  // private revokeChoiceSubscription: Subscription;
  // private noCookieLawSubscription: Subscription;

  constructor(public loggerService: LoggerService, private injectstyles: InjectstylesService, private router: Router, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: Object,/* private ccService: NgcCookieConsentService */ ) {}
 
  ngOnInit() {

    this.loggerService.disableConsoleLogs();

    if(!isDevMode()) {
      // disable devtools
      if(isPlatformBrowser(this.platformId)) {
        // this.disableDevTools();
      }
    }
    
    if (isPlatformBrowser(this.platformId)) {
      
      // this.injectstyles.loadStyle(this.stylesheets);
      // this.injectstyles.loadScript(this.scripts);
      // this.insertFontawesomestyles();
      
      // assign LocalStorage
      this.localStorage = localStorage;

      // this.insertHightlightJSstyles();
      // this.insertMaterialIconstyles();
      // this.initializeGA();

      this.windoCloseListener();
    }

    //Google Analytics Integration
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
          if (isPlatformBrowser(this.platformId)) {

            localStorage.setItem('currentUrl', event.urlAfterRedirects);

            // Initialize Analytics only in Live site not in development
            if(environment && environment.production) {
              if(gtag) {
                gtag('set', 'page', event.urlAfterRedirects);
                gtag('send', 'pageview');
              }
              else {
                setTimeout(() => {
                  gtag('set', 'page', event.urlAfterRedirects);
                  gtag('send', 'pageview');
                }, 1000);
              }
            }
          }
       }
    });

    // Initialize Cookie Consent Events
    // this.cookieConsentEvents();
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      // this.injectstyles.loadStyle(this.stylesheets);
      // this.insertHightlightJSstyles();
      // this.insertMaterialIconstyles();

      // Initialize Analytics only in Live site not in development
      if(environment && environment.production) {
        setTimeout(() => {
          // this.initializeGA();
          // this.initializeAdsense();
          this.adBlockerPopup();
        }, 1500);
      }
    }
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    // this.popupOpenSubscription.unsubscribe();
    // this.popupCloseSubscription.unsubscribe();
    // this.initializeSubscription.unsubscribe();
    // this.statusChangeSubscription.unsubscribe();
    // this.revokeChoiceSubscription.unsubscribe();
    // this.noCookieLawSubscription.unsubscribe();
  }

  insertFontawesomestyles() {
    const css = this.renderer.createElement('link');
    // css.href = "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
    css.href = "https://use.fontawesome.com/releases/v5.1.0/css/all.css";
    // css.href = "https://use.fontawesome.com/releases/v5.15.1/css/all.css";
    css.rel = 'stylesheet';
    this.renderer.appendChild(document.head, css);
  }
  
  insertMaterialIconstyles() {
    const css = this.renderer.createElement('link');
    css.href = "https://fonts.googleapis.com/icon?family=Material+Icons";
    css.rel = 'stylesheet';
    this.renderer.appendChild(document.head, css);
  }

  insertHightlightJSstyles() {
    const css = this.renderer.createElement('link');
    css.href = "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/styles/monokai-sublime.min.css";
    css.rel = 'stylesheet';
    css.onload = this.insertHighlightJS.bind(this);
    this.renderer.appendChild(document.head, css);
  }

  insertHighlightJS() {
    const js = this.renderer.createElement('script');
    js.src = 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/highlight.min.js';
    js.type = 'text/javascript';
    js.text = ``;
    js.async = true;
    js.onload = this.initializeHighlightJS.bind(this);
    this.renderer.appendChild(document.body, js);
  }
  
  initializeHighlightJS() {
    const js = this.renderer.createElement('script');
    js.type = 'text/javascript';
    js.async = true;
    js.text = `hljs.configure({
      languages: ['javascript', 'ruby', 'bash', 'css', 'typescript', 'html']
    });`;
    this.renderer.appendChild(document.body, js);
  }
  
  initializeGA() {
    const js = this.renderer.createElement('script');
    js.type = 'text/javascript';
    js.src = 'https://www.googletagmanager.com/gtag/js?id=G-B6FB60WXH1';
    js.async = true;
    js.onload = this.initializeAdsense.bind(this);
    this.renderer.appendChild(document.body, js);
    
    const js2 = this.renderer.createElement('script');
    js2.type = 'text/javascript';
    js2.text = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'G-B6FB60WXH1');`;
    this.renderer.appendChild(document.body, js2);
  
  }

  adBlockerPopup() {
    const js = this.renderer.createElement('script');
    js.type = 'text/javascript';
    js.src = 'https://fundingchoicesmessages.google.com/i/pub-2297003413569812?ers=1';
    js.nonce = '95qGsfjdi_Xl-WP3uNdsDw';
    js.async = true;
   
    const js2 = this.renderer.createElement('script');
    js2.type = 'text/javascript';
    js2.src = 'https://fundingchoicesmessages.google.com/i/pub-2297003413569812?ers=1';
    js2.nonce = '95qGsfjdi_Xl-WP3uNdsDw';
    js2.async = true;
    js2.text = `(function() {function signalGooglefcPresent() {if (!window.frames['googlefcPresent']) {if (document.body) {const iframe = document.createElement('iframe'); iframe.style = 'width: 0; height: 0; border: none; z-index: -1000; left: -1000px; top: -1000px;'; iframe.style.display = 'none'; iframe.name = 'googlefcPresent'; document.body.appendChild(iframe);} else {setTimeout(signalGooglefcPresent, 0);}}}signalGooglefcPresent();})();`;

    js2.onload = this.initializeAdsense.bind(this);
    this.renderer.appendChild(document.body, js);
    this.renderer.appendChild(document.body, js2);
  }

  initializeAdsense() {
    const js = this.renderer.createElement('script');
    js.type = 'text/javascript';
    js.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812';
    js.async = true;
    js.crossorigin = "anonymous";
    js.onload = this.initializeMicrosoftClarity.bind(this);
    this.renderer.appendChild(document.body, js);
  }

  initializeMicrosoftClarity() {
    const js = this.renderer.createElement('script');
    js.type = 'text/javascript';
    js.defer = true;
    js.text = `(function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () {
          (c[a].q = c[a].q || []).push(arguments)
      };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "er3ey6sqxx");`;
    this.renderer.appendChild(document.body, js);
  }

  // cookieConsentEvents() {
  //   // subscribe to cookieconsent observables to react to main events
  //   this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
  //     () => {
  //       // you can use this.ccService.getConfig() to do stuff...
  //     });
 
  //   this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
  //     () => {
  //       // you can use this.ccService.getConfig() to do stuff...
  //     });
 
  //   this.initializeSubscription = this.ccService.initialize$.subscribe(
  //     (event) => {
  //       // type of  : NgcInitializeEvent
  //       // you can use this.ccService.getConfig() to do stuff...
  //     });
 
  //   this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
  //     (event) => {
  //       // type of : NgcStatusChangeEvent
  //       // you can use this.ccService.getConfig() to do stuff...
  //     });
 
  //   this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
  //     () => {
  //       // you can use this.ccService.getConfig() to do stuff...
  //     });
 
  //     this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
  //     (event) => {
  //       // type of : NgcNoCookieLawEvent
  //       // you can use this.ccService.getConfig() to do stuff...
  //     });
  // }

  disableDevTools() {
    let temp = this;

    // check if aready open
    const element = new Image();
    Object.defineProperty(element, 'id', {
      get: async function () {
        temp.isDevToolsOpen = true;
        window.open(location.href,'_blank');
        setTimeout(function() {
          // this.close(); 
          location.href = "https://www.google.com/";
        },100);
      }
    });
    console.log('%c', element);


    //disable opening of devtools 
    if(!temp.isDevToolsOpen) {
      window.onload = function () {
        document.addEventListener("contextmenu", function (e) {
            e.preventDefault();
        }, false);
  
        document.addEventListener("keydown", function (e) {
            //document.onkeydown = function(e) {
            // "I" key
            if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
                temp.disabledEvent(e);
            }
            // "J" key
            if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
                temp.disabledEvent(e);
            }
            // "S" key + macOS
            if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
                temp.disabledEvent(e);
            }
            // "U" key
            if (e.ctrlKey && e.keyCode == 85) {
                temp.disabledEvent(e);
            }
            // "F12" key
            if (e['keyCode'] == 123) {
                temp.disabledEvent(e);
            }
        }, false);
      }
    }
  }

  disabledEvent(e) {
    if (e.stopPropagation) {
        e.stopPropagation();
    } else if (window.event) {
        window.event.cancelBubble = true;
    }
    e.preventDefault();
    return false;
  }

  windoCloseListener() {
    window.addEventListener("beforeunload", function(e){
      localStorage.removeItem('affiliateData');
   });
  }

}
