import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { BlogserviceService } from '../services/blogservice.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { SetmetadataService } from 'src/app/services/setmetadata.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.less']
})
export class SitemapComponent implements OnInit {
  sitemapData: any;
  env: any = environment;
  localStorage: any;

  constructor(public blogService: BlogserviceService, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private metadataService: SetmetadataService) {}

  ngOnInit(): void {
    let data = {
      title: "Sitemap",
      url: 'https://stacksjar.com/sitemap',
      description: "Sitemap for Stacksjar.com",
      keywords: "Sitemap for Stacksjar.com"
    }

    this.setMetaTags(data);
    this.setLinkTag();

    this.getSitemapData();
  }

  setMetaTags(meta) {
    this.metadataService.setMetaTags(meta);
  }

  setLinkTag() {
    const item: any = this.document.querySelector('link[rel="canonical"]');

    if (item) {
      item.href = `https://stacksjar.com/sitemap`;
    } else {
      const link = this.renderer.createElement('link');
      link.rel = 'canonical';
      link.href = `https://stacksjar.com/sitemap`;

      this.renderer.appendChild(this.document.head, link);
    }
  }

  getSitemapData() {
    this.blogService.getSitemapData().subscribe((res: any) => {
      if (res) {
        this.sitemapData = res;
      }
    });
  }

  ngOnDestroy() {
    const item: any = document.querySelector('link[rel="canonical"]');
    if (item) {
      document.head.removeChild(item);
    }
  }

}
