import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogserviceService } from '../services/blogservice.service';

@Component({
  selector: 'app-misc',
  templateUrl: './misc.component.html',
  styleUrls: ['./misc.component.less']
})
export class MiscComponent implements OnInit {
  data: any;
  action: string;
  resMsg: string;
  statusCode: number;
  showMessage: boolean = false;

  constructor(private activatedRoute: ActivatedRoute, private blogService: BlogserviceService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(res => {
      if(res) {
        this.action = res.type;
      }
    });

    this.activatedRoute.queryParams.subscribe(queryParams => {
      if(queryParams) {
        this.data = queryParams['email'];
        this.callToAction();
      }
    })
  }

  callToAction() {
    switch(this.action) {
      case 'unsubscribe': 
        this.unsubscribeNewsletter();
        break;
      default:
        break;
    }
  }
  
  unsubscribeNewsletter() {
    let data = {
      'email' : this.data
    }

    this.blogService.unsubscribeToNewsLetter(data).subscribe(res => {
      if(res) {
        this.showMessage = true;
        this.resMsg = res['msg'];
        this.statusCode = res['statusCode'];
      }
    },
    err => {
      if(err) {
        this.showMessage = true;
        this.resMsg = err['error']['msg'];
        this.statusCode = err['error']['statusCode'];
      }
    });  
  }

}
