import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SnippetsService {
  baseUrl = environment.baseUrl;
  tagsRes: any = [];

  constructor(private http: HttpClient) { }

  getSnippets(data, tagUrl?) {
    let final = this.baseUrl + 'fetch';
    
    if(tagUrl) {
      final = `${final}/${tagUrl}`;
    }
    
    return this.http.post(final, data);
  }

  snippetDetails(url) {
    let final = this.baseUrl + 'get/' + url;
   
    return this.http.get(final);
  }
  
  getTags() {
    let final = this.baseUrl + 'tags';
   
    return this.http.get(final);
  }

  saveSnippet(data, method, snippetID?) {
    let final = this.baseUrl;
    
    if(method == 'update') {
      final +=  `update/${snippetID}`;
      return this.http.post(final, data);
    }
    else {
      final += `new`; 
      return this.http.post(final, data);
    }
  }

  checkSnippetForEdit(id) {
    let final = this.baseUrl + 'check/' + id;
    
    return this.http.get(final);
  }

  updateViewCounter(url) {
    let final = this.baseUrl + 'updateSnippetsMeta';
    let data =  {'content_id': url};
    return this.http.post(final, data, {headers: { ignoreLoadingBar: '' }});
  }

  
}
