import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreloadService implements PreloadingStrategy {

  constructor() { }
  
  preload(route: Route, load: () => Observable<any>): Observable<any> {
    if (route.data && route.data['preload']) {
      // return load();
      const delay:number = route.data['delay']

      // console.log('preload called on '+route.path+' delay is '+delay);
      return timer(delay).pipe(
        flatMap( _ => { 
          // console.log("Loading now "+ route.path);
          return load() ;
        }));
    } 
    else {
      return of(null);
    }
  }
}