import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { BrowserModule } from '@angular/platform-browser';
import { APP_ID, CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
// import { DetailsComponent } from './details/details.component';

// import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { HttprequestInterceptor } from './services/httprequest.interceptor';
// import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// import { LoadingBarModule } from '@ngx-loading-bar/core';
// import { DisqusModule } from "ngx-disqus";
import { DISQUS_SHORTNAME } from 'ngx-disqus';

// NPM
// import Counter from './services/counter';

// QuIll
// import { QuillModule } from 'ngx-quill';
import { ListingComponent } from './listing/listing.component';

// Social Share
// import { ShareModule } from 'ngx-sharebuttons';
// import { MatFabMenuModule } from '@angular-material-extensions/fab-menu';
import { Home2Component } from './home2/home2.component';
// import { CheckadminDirective } from './services/checkadmin.directive';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { environment } from 'src/environments/environment';

// cookie consent
// import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
// import { DatanotfoundComponent } from './datanotfound/datanotfound.component';
import { SharedmoduleModule } from './sharedmodule/sharedmodule.module';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MiscComponent } from './misc/misc.component';

// const cookieConfig:NgcCookieConsentConfig = {
//   cookie: {
//     domain: 'stacksjar.com' // it is recommended to set your domain, for cookies to work properly
//   },
//   position: "bottom",
//   theme: "classic",
//   palette: {
//     popup: {
//       background: "#192448",
//       text: "#ffffff",
//       link: "#ffffff"
//     },
//     button: {
//       background: "#ffffff",
//       text: "#192448",
//       border: "transparent"
//     }
//   },
//   type: "info",
//   content: {
//     message: "This website uses cookies to ensure you get the best experience on our website.",
//     dismiss: "Got it!",
//     deny: "Refuse cookies",
//     link: "Learn more",
//     href: "/privacy-policy",
//     policy: "Cookie Policy"
//   }
// }


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    // DetailsComponent,
    ListingComponent,
    Home2Component,
    // CheckadminDirective,
    PagenotfoundComponent,
    // DatanotfoundComponent,
    MiscComponent
  ],
  imports: [
    // BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    // HttpClientModule,
    // CKEditorModule,
    // QuillModule.forRoot(),
    // ShareModule,
    BrowserAnimationsModule,
    // LoadingBarHttpClientModule,
    // LoadingBarModule,
    // MatFabMenuModule,
    // DisqusModule.forRoot('stacksjar-com'),
    // NgcCookieConsentModule.forRoot(cookieConfig),
    SharedmoduleModule,
    InputSwitchModule,
  ],
  providers: [ 
    // { provide: HTTP_INTERCEPTORS, useClass: HttprequestInterceptor, multi: true }
    { provide: DISQUS_SHORTNAME, useValue: 'stacksjar-com' },
    { provide: APP_ID, useValue: 'serverApp' }
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }
