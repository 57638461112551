import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogserviceService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  getBlogs(data, catURL?) {
    let final = this.baseUrl + 'getBlogs';
    
    if(catURL) {
      final = `${final}/${catURL}`;
    }
    
    // const headers = {
    //     'Access-Control-Allow-Origin': '*', 
    //     'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    //     'Accept' :'application/json',
    //     'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token',
    //   }
  
    return this.http.post(final, data);
  }
  
  blogDetails(url) {
    let final = this.baseUrl + 'blogDetail/' + url;
    
    // const headers = {
    //     'Access-Control-Allow-Origin': '*', 
    //     'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    //     'Accept' :'application/json',
    //     'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token',
    //   }
  
    return this.http.get(final);
  }

  getUserblogs() {
    let final = this.baseUrl + 'blog';
    
    // const headers = {
    //     'Access-Control-Allow-Origin': '*', 
    //     'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    //     'Accept' :'application/json',
    //     'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token',
    //     'Authorization' : `Bearer ${localStorage.getItem('bearer')}`
    //   }
  
    return this.http.get(final);
  }

  getAllPosts(data)  {
    let final = this.baseUrl + 'getAllPosts';
    return this.http.post(final, data);
  }

  editBlog(id) {
    let final = this.baseUrl + 'editBlog/' + id;
    
    // const headers = {
    //     'Access-Control-Allow-Origin': '*', 
    //     'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    //     'Accept' :'application/json',
    //     'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token',
    //     'Authorization' : `Bearer ${localStorage.getItem('bearer')}`
    //   }
  
    return this.http.get(final);
  }

  deleteBlog(blogID) {
    let final = this.baseUrl + 'blog/' + blogID;
    return this.http.delete(final, { observe: 'response' });
  }
  
  getCategories() {
    let final = this.baseUrl + 'getCategory';
    return this.http.get(final);
  }
  
  getAdditionalPosts(data) {
    let final = this.baseUrl + 'additionalPosts';
    return this.http.post(final, data);
  }
  
  unsubscribeToNewsLetter(data) {
    let final = this.baseUrl + 'unsubscribe';
    return this.http.post(final, data);  
  }
  
  subscribeToNewsletter(data) {
    let final = this.baseUrl + 'subscribe';
    return this.http.post(final, data);  
  }

  updateViewCounter(url) {
    let final = this.baseUrl + 'updateMeta';
    let data =  {'content_id': url};
    return this.http.post(final, data, {headers: { ignoreLoadingBar: '' }});
  }
  
  dashboard() {
    let final = this.baseUrl + 'dashboard';
    return this.http.get(final);
  }

  getSitemapData() {
    let final = this.baseUrl + 'sitemap';

    return this.http.get(final);
  }

  getAffiliateProducts() {
    const cachedData = this.getAffiliateDataFromLocalStorage();
    if(cachedData) {
      return cachedData;
    }
    else {
      let final = "/assets/data/affiliateData.json";
      return this.http.get(final);
    }

  }
  
  getAffiliateDataFromLocalStorage() {
    const data = localStorage.getItem('affiliateData');
    if(data) {
      const affiliateData = JSON.parse(data);

      return of(affiliateData);
    }
    else {
      return null;
    }
  }

  setAffiliateDataToLocalStorage(data) {
    const updatedData = data;
    updatedData.dataSource = "cache";
    localStorage.setItem('affiliateData', JSON.stringify(updatedData));
  }

  resetAffiliateData() {
    localStorage.removeItem('affiliateData');
  }

}
