<div class="container-fluid background">
    <div class="row">
        <div class="col-md-4 message-container">
            <div class="message-card" *ngIf="showMessage">
                <div class="card-img">
                    <img src="assets/images/plane.svg" alt="">
                </div>
                <div class="text-wrapper">
                    <p class="status">{{statusCode == 200 ? 'SUCCESS' : 'FAILURE'}}</p>
                    <p class="message" [ngClass]="statusCode == 200 ? 'success' : 'failure'">
                        {{resMsg}}
                    </p>
                </div>
                <!-- <div class="button-wrapper">
                    <button routerLink="/" class="btn primary">Home</button>
                </div> -->
            </div>
        </div>
    </div>
</div>

<ngx-loading-bar [includeBar]="false" [diameter]="'34px'"></ngx-loading-bar>