<div class="subscribe-wrapper">
  <h4 class="title">
    Newsletters 📪
  </h4>
  <div class="section-content">
    <span class="title">Get Every Newsletter</span>
    <span class="desc">Get notified of the all the trending blog posts.</span>
  </div>
  <div class="email-wrapper">
    <div class="input-wrapper">
      <div class="input">
        <input #emailInput="ngModel" (keyup)="emailInputs(emailInput)" (keydown.enter)="subscribeNewsletter(emailInput)"
          [ngClass]="emailInput.isInvalid ? 'error' : ''" [(ngModel)]="emailId" type="email" placeholder="Email"
          required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" />
        <button type="submit" class="submit-email" (click)="subscribeNewsletter(emailInput)"
          [disabled]="emailInput.loading || emailInput.completed" [ngClass]="emailInput.loading && !emailInput.completed ? 'animate' : ''">
          <span class="button-icon" >
            <i class="fas fa-wind"></i>
          </span>
          <span class="button-icon">
            <i class="far fa-paper-plane"></i> </span>
        </button>
      </div>
    </div>
  </div>
</div>