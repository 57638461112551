import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, HostListener, Inject, Input, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ads, adsScript } from '../../assets/data/adsData';

@Directive({
  selector: '[Adshandler]'
})
export class AdshandlerDirective {
  @Input('data') data;
  @Input('lazyLoad') lazyLoad;
  ads: any = ads;
  adsScript: any = adsScript;
  adsData:any;
  showOnHover: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private renderer: Renderer2,  private elem: ElementRef) {}

  @HostListener('window:scroll')
  public onScrollListener(): void {
    this.isItInView();
  }

  // @HostListener('mouseenter')
  // public mouseenterListener(): void {
  //   this.checkAdsInDom();
  // }

  // @HostListener('mouseleave')
  // public mouseleaveListener(): void {
  //   this.showOnHover = false;
  // }

  ngOnInit(): void {

    this.adsData = this.ads[this.data] ? this.ads[this.data] : this.adsScript[this.data];

    this.showOnHover = this.lazyLoad;
    // this.adsenseScript();

    if (isPlatformBrowser(this.platformId)) {
      if(!this.showOnHover) {
        this.injectAds();
      }
    }
  }

  injectAds() {
    // let adsBlock = document.getElementById('ads-block');
    let adsBlock = this.elem.nativeElement;

    if(adsBlock && this.adsData) {
      let insHtml = this.renderer.createElement('ins');

      insHtml.className = "adsbygoogle";
      insHtml.style.display = this.adsData['display'];

      Object.keys(this.adsData).forEach(item => {
        if(item != 'display') {
          insHtml.setAttribute(item, this.adsData[item]);
        }
      })


      let script = document.createElement('script');
      script.innerHTML = "(adsbygoogle = window.adsbygoogle || []).push({})";
      
      this.renderer.appendChild(adsBlock, insHtml);
      this.renderer.appendChild(adsBlock, script);
      
    }
    else {
      setTimeout(() => {
        this.injectAds();
      }, 2000);
    }
  }

  // ngAfterViewInit() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     setTimeout(()=>{
  //      try{
         
  //       (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
        
  //       // this.injectAds();
  //       this.elem.nativeElement.innerHtml = this.adsScript['listingScript'];

  //      }
  //      catch(e){
  //        console.error("error");
  //      }
  //    },2000);
  //   }
  // }

  adsenseScript() {
    setTimeout(()=>{
     try{
       (window['adsbygoogle'] = window['adsbygoogle'] || []).push({});
     }catch(e){
       console.log("adsense error");
      }
    },2000);
  }  

  checkAdsInDom() {
    // let adsBlock = document.getElementById('ads-block');
    let adsBlock = this.elem.nativeElement;

    if(adsBlock && (!adsBlock.firstChild || adsBlock.firstChild['tagName'] != "INS")) {

      // Or Condition
      // || (adsBlock.firstChild.getAttribute('data-adsbygoogle-status') == "done" && (!adsBlock.firstChild.getAttribute('data-ad-status') || adsBlock.firstChild.getAttribute('data-ad-status') == "unfilled"))

      // for(let item = 0; item < this.elem.nativeElement.children.length; item++) {
      //   this.elem.nativeElement.removeChild(this.elem.nativeElement.children[item]);
      // }
      this.injectAds();
    }
    else if((adsBlock.firstChild['tagName'] == "INS" && adsBlock.firstChild.children.length == 0) || (adsBlock.firstChild.getAttribute('data-adsbygoogle-status') == "done" && (!adsBlock.firstChild.getAttribute('data-ad-status') || adsBlock.firstChild.getAttribute('data-ad-status') == "unfilled"))) {
      setTimeout(() => {
        if((adsBlock.firstChild.getAttribute('data-adsbygoogle-status') == "done" && (!adsBlock.firstChild.getAttribute('data-ad-status') || adsBlock.firstChild.getAttribute('data-ad-status') == "unfilled"))) {
          adsBlock.innerHTML = "";
          this.injectAds();
        }
      }, 700)
    }
  }

  isItInView() {
    // const adsElement: HTMLElement = document.getElementById(id);
    let adsElement = this.elem.nativeElement;

    if (adsElement) {
        const bounding = adsElement.getBoundingClientRect();
        if (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        ) {
          this.checkAdsInDom();
        }
    }
}  

}