import { Component } from '@angular/core';
import { SnippetsService } from 'src/app/services/snippets.service';

@Component({
  selector: 'app-tagswidget',
  templateUrl: './tagswidget.component.html',
  styleUrls: ['./tagswidget.component.less']
})
export class TagswidgetComponent {
  tagsData:any = [];

  constructor(private snippetService: SnippetsService) {}

  ngOnInit() {
    this.getTags();
  }

  getTags() {
    if(this.snippetService.tagsRes && this.snippetService.tagsRes.length > 0) {
      this.tagsData = this.snippetService.tagsRes;
    }
    else {
        this.snippetService.getTags().subscribe(res => {
          if(res) {
            this.snippetService.tagsRes = res['data'];
            this.tagsData = res['data'];
          }
        });
    }
  }
}
