import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Home2Component } from './home2/home2.component';
// import { DetailsComponent } from './details/details.component';
import { ListingComponent } from './listing/listing.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { MiscComponent } from './misc/misc.component';

import { PreloadService } from './services/preload.service';

import { SitemapComponent } from './sitemap/sitemap.component';

// import { DashboardComponent } from './dashboard/dashboard.component';
// import { QuillComponent } from './quill/quill.component';
// import { ContactusComponent } from './contactus/contactus.component';
// import { DisclaimerComponent } from './disclaimer/disclaimer.component';
// import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
// import { TermsandconditionsComponent } from './termsandconditions/termsandconditions.component';
// import { LoginComponent } from './login/login.component';

const routes: Routes = [
  // {path: '', redirectTo: '/home', pathMatch: 'full'},
  // {path: 'blog/:blogURL', component: DetailsComponent},
  // {path: 'list', component: ListingComponent},
  {path: '', component: Home2Component},
  {path: 'home', component: Home2Component},
  {path: 'category/:categoryID', component: ListingComponent},
  {
    path: 'user', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'apps', 
    loadChildren: () => import('./appsmodule/appsmodule.module').then(m => m.AppsmoduleModule)
  },
  {
    path: 'tools', 
    loadChildren: () => import('./toolsmodule/tools.module').then(m => m.ToolsModule)
  },
  {
    path: '', 
    loadChildren: () => import('./detailsmodule/detailsmodule.module').then(m => m.DetailsmoduleModule),
    data: { preload: true, delay: 5000 } 
  },
  {
    path: '', 
    loadChildren: () => import('./quicksnippets/quicksnippets.module').then(m => m.QuicksnippetsModule),
  },
  {
    path: 'products', 
    loadChildren: () => import('./affiliates/affiliates.module').then(m => m.AffiliatesModule),
  },
  {
    path: 'cheatsheet', 
    loadChildren: () => import('./cheatsheetmodule/cheatsheetmodule.module').then(m => m.CheatsheetmoduleModule)
  },
  {
    path: 'games', 
    loadChildren: () => import('./gamesmodule/gamesmodule.module').then(m => m.GamesmoduleModule)
  },
  // {path: 'post/:blogURL', component: DetailsComponent},
  {path: 'sitemap', component: SitemapComponent},
  {path: 'misc/:type', component: MiscComponent},
  {path: '404', component: PagenotfoundComponent},
  {
    path: '', 
    loadChildren: () => import('./contentmodule/contentmodule.module').then(m => m.ContentmoduleModule),
    data: {preload: true, delay: 5000}
  },
  {
    path: '', 
    loadChildren: () => import('./changelogsmodule/changelogsmodule.module').then(m => m.ChangelogsmoduleModule),
    data: {preload: true, delay: 5000}
  },
  {path: '**', redirectTo: '404'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    initialNavigation: 'enabledBlocking',
    preloadingStrategy: PreloadService, 
    anchorScrolling: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
