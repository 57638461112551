import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserserviceService {
  baseUrl = environment.baseUrl;

  constructor(private http: HttpClient) { }

  loginUser(data) {
    const final = this.baseUrl + 'login';

    const payload = new HttpParams().set('email', data.email).set('password', data.password);

  //   const headers = { 'Access-Control-Allow-Origin': '*', 
  //   'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  //   'Content-Type' : 'application/x-www-form-urlencoded',
  //   'Accept' :'application/json',
  //   'Accept-Encoding' :'gzip, deflate, br',
  //   'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token'
  // }

    return this.http.post(final, payload);
  }
  
  registerUser(data) {
    const final = this.baseUrl + 'register';

    const payload = new HttpParams()
                          .set('fname', data.fname)
                          .set('lname', data.lname)
                          .set('email', data.email)
                          .set('password', data.password)
                          .set('password_confirmation', data.cpassword);

  //   const headers = { 'Access-Control-Allow-Origin': '*', 
  //   'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  //   'Content-Type' : 'application/x-www-form-urlencoded',
  //   'Accept' :'application/json',
  //   'Accept-Encoding' :'gzip, deflate, br',
  //   'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token'
  // }

    return this.http.post(final, payload);
  }

  uploadPhoto(data) {
    let final = this.baseUrl + 'uploadBlogPhoto';
    
    const formData: FormData = new FormData();
    const payload = new HttpParams().set('photo', data);
  //   const headers = { 'Access-Control-Allow-Origin': '*', 
  //   'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  //   // 'Content-Type' : 'multipart/form-data',
  //   'Accept' :'application/json',
  //   // 'Accept-Encoding' :'gzip, deflate, br',
  //   'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token',
  //   'Authorization' : `Bearer ${localStorage.getItem('bearer')}`
  // }

    formData.append("photo", data);
    return this.http.post(final, formData);


  }

  deletePhoto(img) {
    const final = this.baseUrl + 'deleteBlogPhoto/' + img;
    
  //   const headers = { 'Access-Control-Allow-Origin': '*', 
  //   'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  //   'Accept' :'application/json',
  //   'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token',
  //   'Authorization' : `Bearer ${localStorage.getItem('bearer')}`
  // }

    return this.http.get(final);
  }
  
  createBlog(data, method, blogID?) {
    let final = this.baseUrl + 'blog';
    
  //   const headers = { 'Access-Control-Allow-Origin': '*', 
  //   'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  //   'Accept' :'application/json',
  //   'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token',
  //   'Authorization' : `Bearer ${localStorage.getItem('bearer')}`
  // }
  
    if(method == 'update') {
      final +=  `/${blogID}`;
      return this.http.patch(final, data);
    }
    else {
      return this.http.post(final, data);
    }
  }

  updateUser(data) {
    const final = this.baseUrl + 'updateUser';

    // const headers = { 'Access-Control-Allow-Origin': '*', 
    // 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    // 'Content-Type' : 'application/x-www-form-urlencoded',
    // 'Accept' :'application/json',
    // 'Accept-Encoding' :'gzip, deflate, br',
    // 'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token'
    // }

    // return this.http.post(final, payload, {headers});
    return this.http.post(final, data);
  }

  logout() {
    const final = this.baseUrl + 'logout';

  //   const headers = { 'Access-Control-Allow-Origin': '*', 
  //   'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  //   'Accept' :'application/json',
  //   'Access-Control-Allow-Headers' : 'Origin, Content-Type, X-Auth-Token',
  //   'Authorization' : `Bearer ${localStorage.getItem('bearer')}`
  // }

    return this.http.get(final);
  }

  searchUser(names) {
    const final = this.baseUrl + 'searchUser';

    let data = {
      name: names
    }
    
    return this.http.post(final, data);
  }
  
  getAllUsers(data) {
    const final = this.baseUrl + 'getAllUsers';

    return this.http.post(final, data);
  }

  uploadUserPhoto(data) {
    let final = this.baseUrl + 'uploadPhoto';
    
    const formData: FormData = new FormData();
    const payload = new HttpParams().set('photo', data);

    formData.append("photo", data);
    return this.http.post(final, formData);
  }

  deleteUser(id) {
    const final = this.baseUrl + 'deleteUser/' + (id ? id : '');
    return this.http.get(final);
  }
  
  submitContact(data) {
    const final = this.baseUrl + 'contact';
    return this.http.post(final, data);
  }

 }

