import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, of, pipe } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
// import { NotifyService } from './notify.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { makeStateKey, StateKey, TransferState } from '@angular/core';

@Injectable()
export class HttprequestInterceptor implements HttpInterceptor {
  // reqCounter: number = 0;


  // **** code to avoid flickering of data 
  // **** code to avoid flickering of data 
  constructor(private transferState: TransferState, @Inject(PLATFORM_ID) private platformId: object, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {


    let bearer;
    if (isPlatformBrowser(this.platformId)) {
      bearer = localStorage.getItem('bearer');
    }
    let headers = {};

    if(!request.url.includes('api.quotable.io') && !request.url.includes('meme-api') && !request.url.includes('getBlogs/') &&  !request.url.includes('blogDetails/') && bearer) {
      headers = {
        'Authorization': bearer
      };
    }

    const newReq = request.clone({
      setHeaders: headers
    });

    // **** code to avoid flickering of data 
    if (request.method === 'POST' && !request.url.includes('getBlogs')) {
        return next.handle(newReq);
      }
      
    const key: StateKey<string> = makeStateKey<string>(request.url);
      
    if (isPlatformServer(this.platformId)) {
      //serverSide
      return next.handle(newReq).pipe(tap((event) => {
        this.transferState.set(key, (<HttpResponse<any>> event).body);
      })); 
    }
    else {
      //browserSide
      const storedResponse = this.transferState.get<any>(key, null);
      if (storedResponse) {
        const response = new HttpResponse({body: storedResponse, status: 200});
        this.transferState.remove(key);
        return of(response);
      } 
      else {

    // **** code to avoid flickering of data 
        return next.handle(newReq).pipe(
          tap(
            (response: HttpEvent<any>) => {
              return response;
            },
            (error: HttpErrorResponse) => {
              let errMsg;
              if (error.error) {
                if (error.error['errors']) {
                  errMsg = [];
                  for (let err of Object.keys(error.error['errors'])) {
                    if (error.error['errors'][err]) {
                      errMsg.push(...error.error['errors'][err]);
                    }
                  }
                  errMsg = `Error:- ${errMsg.join(', ')}`;
                } else {
                  errMsg = error.error['message'] ? error.error['message'] : 'Failed to Load Data';
                }
              } else {
                errMsg = error.statusText ? error.statusText : 'Failed';
              }
    
              // Notify 
              if(error.error && error.status != 400) {
                if (error.status == 304 || error?.error?.message == 'Nothing Updated') {
                  // this.notifyService.reportMethod('INFO', 'Nothing Updated', 'ok');
                } else {
                  // this.notifyService.reportMethod('FAILURE', errMsg, 'ok');
                }
              }
    
              // Remove User Credentials if Unauthorised
              if (error.status == 401) {
                if (isPlatformBrowser(this.platformId)) {
                  if (localStorage.getItem("isLoggedIn") == "true") {
                    localStorage.removeItem("userDetails");
                    localStorage.removeItem("bearer");
                    localStorage.setItem("isLoggedIn", "false");
                    this.router.navigate(["/home"]);
                  }
                } else {
                  this.router.navigate(["/user/login"]);
                }
              }
            }),
          finalize(() => {
            // this.reqCounter -= 1;
            // if(this.reqCounter == 0) {
            //   localStorage.setItem('showLoader', 'false');
            // }
          })
        );
      }
    }
  }

}
