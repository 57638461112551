<div class="container-fluid">
  <div class="row landing-wrapper">
    <div class="col-md-6 content-wrap">
      <div class="content">
        <p class="tag-line">
          High Quality Practical Resources to skill up your Web Development Career and Boost your Productivity
        </p>
        <div class="subscibe-wrapper row no-gutters">
          <div class="col-sm-9 col-md-9 pr-2 input-wrap">
            <input #emailInput="ngModel" (keyup)="emailInputs(emailInput)" (keydown.enter)="subscribeNewsletter(emailInput)"
            [ngClass]="emailInput.isInvalid ? 'error' : ''" [(ngModel)]="emailId" type="email" placeholder="Email" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" />
          </div>  
          <div class="col-sm-3 col-md-3">
            <button class="subscribe-btn" (click)="subscribeNewsletter(emailInput)" [disabled]="emailInput.loading || emailInput.completed">
              <span *ngIf="!emailInput.loading && !emailInput.completed">
                Subscribe
              </span>
              <span class="spinner" *ngIf="emailInput.loading"></span>
              <span class="completed" *ngIf="emailInput.completed">
                <i class="far fa-check-circle"></i>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 img-wrap">
      <img src="assets/images/landing6.webp" width="580" height="330" alt="landing-image" loading="lazy">
    </div>
  </div>
  <div class="row">
    <div class="parallax" [ngStyle]="{ 'background-image': 'url(' + svgUrl + ')'}">
      <section #facts id="facts" class="col-md-12 facts-section spacing back-color">
        <label class="section-title">
          You will Learn
        </label>
        <div class="row">
          <div class="col-sm-6 col-md-3" *ngFor="let item of factsData;let i=index;" >
            <div class="facts-item">
              <div class="head">
                <span class="icon">
                  <i class="{{item.icon}}"></i>
                </span>
                <span class="title">
                  {{item.title}}
                </span>
              </div>
              <p class="description">
                {{item.body}}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- <div class="col-md-12 banner-wrapper">
          <div class="col-md-6 image"> </div>
          <div class="col-md-6 text">  </div>
        </div> -->

  </div>
</div>
<app-listing></app-listing>
