<div class="affiliate-wrapper" *ngIf="productData">
    <a href="{{productData.url}}" target="_blank">
        <div class="product-image">
            <img src="../../../assets//affiliateProducts/{{productData.image}}" alt="product-image" class="img-fluid">
        </div>
        <div class="description">
            <span class="category">
                {{productData.category}}
            </span>
            <span class="name">
                {{productData.name}}
            </span>
            <span class="price">
                ₹{{productData.price}}
            </span>
            <!-- <span class="discount">
                {{productData.discount}}
            </span> -->
        </div>
    </a>
    <!-- <div class="button-wrapper">
        <button href="">View More</button>
    </div> -->
</div>