import { Component, Inject, InjectionToken, OnInit, PLATFORM_ID } from '@angular/core';
import { UserserviceService } from '../services/userservice.service';
import { NavigationService } from '../services/navigation.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import { GagtmService } from '../services/gagtm.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit {
  navigations: any;
  localStorage: any;
  userDetails: JSON;
  authorImgUrl = environment.userImages;
  
  constructor(@Inject(PLATFORM_ID) private platformId: object,  private userService: UserserviceService, private router: Router, private navigationService: NavigationService,
  private gagtm: GagtmService) {}
  menu = false;

  ngOnInit() {
    this.getNavigations();
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage = localStorage;
      if(localStorage.getItem('userDetails')) {
        this.userDetails = JSON.parse(localStorage.getItem('userDetails'));
      }
    }
  }

  clearPagination() {
    if(isPlatformBrowser(this.platformId)) {
      this.localStorage.removeItem('pageNo');
    }
  }

  menuToggle(val) {
    if(val) {
      this.menu=true;
    } else {
      this.menu=false;
    }
  }

  getUserName() {
    if (isPlatformBrowser(this.platformId)) {
      return JSON.parse(localStorage.getItem('userDetails'))?.name;
    }
  }
  
  getUserPhoto() {
    if (isPlatformBrowser(this.platformId)) {
      if(localStorage.getItem('userDetails')) {
        return JSON.parse(localStorage.getItem('userDetails')).photo;
      }
      else {
        return false;
      }
    }
  }


  search() {
    console.log("hello search icon is clicked");
  }

  logout() {
    this.userService.logout().subscribe(res=>{
      if (isPlatformBrowser(this.platformId)) {
        if(res && res['message'] == "Successfully logged out" && res['statusCode'] == 200) {
          localStorage.removeItem('userDetails');
          localStorage.removeItem('bearer');
          localStorage.removeItem('isLoggedIn');
          this.router.navigate(['/']);
        }
      }
    })
  }

  getNavigations() {
    this.navigationService.getNavs('header').subscribe(res => {
      if(res && res['navigations']) {
        // this.navigations = res['navigations'];
        this.groupNavs(res['navigations']);
      }
    })
  }

  groupNavs(data) {
    if(data) {
      let newArr = [];
      newArr = data.reduce((previous, current)=> {
          let idx = previous.findIndex(f => f.id == current.parent_id);
          if(idx > -1) {
            previous[idx]['subNavs'].push(current);
          }
          else {
            current['subNavs'] = [];
            previous.push(current);
          }
        return previous;
      }, []);
    
      this.navigations = newArr;
    }
  }

  toggleSidebar() {
    if (isPlatformBrowser(this.platformId)) {
      if(localStorage.getItem('sideBar') == 'true') {
        localStorage.setItem('sideBar', 'false');
      }
      else {
        localStorage.setItem('sideBar', 'true');
      }
    }
  }

  checkCurrentPage() {
    if(this.router.url.includes('dashboard')) {
      return false;
    }
    else {
      return true;
    }
  }

  onMouseLeave(nav) {
    if(nav.subNavs && nav.subNavs.length > 0) {
      nav.drpOpen = false;
  
      // gagtm event tracking
      const data = {
        event: 'NavLink_Click',
        value: 'Dropdown Close - ' + nav.name
      }
      this.gagtm.triggerEvent(data);
    }
  }

  toggleDropdown(event, nav) {
    if(nav.subNavs && nav.subNavs.length > 0) {
      nav.drpOpen = !nav.drpOpen;

      // gagtm event tracking
      const data = {
        event: 'NavLink_Click',
        value: nav.drpOpen ? 'Dropdown Open - ' + nav.name : 'Dropdown Close - ' + nav.name
      }
      this.gagtm.triggerEvent(data);
    }
  }

  routeToListing(event, nav) {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
    }
    
    this.router.navigateByUrl(nav.url);
    this.menu = false;

    const drpElement = this.navigations.find(f => f.drpOpen == true);
    if(drpElement) {
      drpElement.drpOpen = false;
    } 
    
    // gagtm event tracking
    const data = {
      event: 'NavLink_Click',
      value: nav.name
    }
    this.gagtm.triggerEvent(data);
  }
}
