<!-- <nav class="navbar navbar-expand-lg navbar-dark bg-primary navbar-expand-md d-none d-md-flex">
    <a class="navbar-brand" href="#"><h3>LiT-FactS</h3></a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Contact Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Privacy Policy</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">About Us</a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Categories
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#">FEEDS</a>
            <a class="dropdown-item" href="#">RECIPIE</a>
            <a class="dropdown-item" href="#">BOLLYWOOD</a>
            <a class="dropdown-item" href="#">OOTD</a>
            <a class="dropdown-item" href="#">DIY</a>
            <a class="dropdown-item" href="#">MEMES</a>
            <a class="dropdown-item" href="#">SELFIE OF THE DAY</a>
          </div> 
        </li>
      </ul>
      <form class="form-inline my-2 my-lg-0 lsearch">
        <input class="form-control" type="search" placeholder="Search.." aria-label="Search">
      </form>
    </div>
  </nav>
  <div class="container mobile-nav d-block d-sm-none">
    <div class="row">
      <div class="col-2 col-sm-2">
        <i class="fas fa-bars mmenu" ></i>
      </div>
      <div class="col-8 col-sm-8">
        <h3>LITFACTS</h3>
      </div>
      <div class="col-2 col-sm-2">
        <form class="form-inline my-2 my-lg-0 msearch">
          <input class="form-control mr-sm-2" type="search" placeholder="Search.." aria-label="Search">
          <span>
        <i class="fas fa-search searchicon" (click)="search()"></i>
        </span>
        </form>
      </div>
    </div>
  </div>
  <div class="container popover" [ngClass]="menu?'showm':'hidem'">
    <span class="menuToggle">
        <img src="./assets/images/error.svg"  style="width: 24px; height: 24px;">
    </span>
      <ul>
        <li>
          <a routerLink="Home">Home</a>
        </li>
        <li>
          <a routerLink="Memes">Memes</a>
        </li>
        <li>
          <a routerLink="Recipies">Recipies</a>
        </li>
        <li>
          <a routerLink="Offline">Offline</a>
        </li>
        <li>
          <a routerLink="Contact Us">Contact Us</a>
        </li>
        <li>
          <a routerLink="Feedback">Feedback</a>
        </li>
      </ul>
  </div>
   -->

<!-- Just an image -->
<nav class="navbar navbar-expand-lg navbar-light">
  <a class="navbar-brand" routerLink="/" (click)="clearPagination()">
    <img class="img-fluid logo" src="assets/images/logo.png" alt="logo" width="150" height="40">
    <!-- StacksJar -->
  </a>
  <button *ngIf="!checkCurrentPage()" class="navbar-toggler d-block d-md-none" type="button" (click)="toggleSidebar()">
    <!-- <i class="custom-icon"></i> -->
    <i class="fas fa-bars"></i>
  </button>
  <button (click)="menu = !menu;" [ngClass]="menu ? 'active' : 'inactive'" *ngIf="checkCurrentPage()" class="navbar-toggler">
    <i class="custom-icon" ></i>
  </button>
  <div *ngIf="checkCurrentPage()" class="navbar-collapse" [ngClass]="!menu ? 'collapse' : ''">
    <ul class="navbar-nav col-lg-10" *ngIf="navigations?.length > 0">
      <li class="nav-item" *ngFor="let nav of navigations | orderBy : 'sort_order'" [ngClass]="nav.subNavs?.length > 0 ? 'dropdown-wrap' : ''">
        <a class="nav-link" *ngIf="nav.subNavs.length == 0" routerLink="{{nav.url}}" [ngClass]="{'active' : nav.drpOpen, 'drp-btn' : nav.subNavs.length > 0}" (click)="routeToListing($event, nav)">
          <span class="text">
            {{nav.name}}
          </span>
          <span class="icon" *ngIf="nav.icon">
            <i class="{{nav.icon}}"></i>
          </span>
        </a>
        <a class="nav-link" *ngIf="nav.subNavs && nav.subNavs.length > 0" href="javascript:;" [ngClass]="{'active' : nav.drpOpen, 'drp-btn' : nav.subNavs.length > 0}" (click)="toggleDropdown($event, nav)" (mouseleave)="onMouseLeave(nav)">
          <span class="text">
            {{nav.name}}
          </span>
          <span class="icon" *ngIf="nav.icon">
            <i class="{{nav.icon}}"></i>
          </span>
          <div class="drp-menu" *ngIf="nav.subNavs && nav.subNavs.length > 0">
            <a class="drp-item" (click)="routeToListing($event, subNav)" routerLink="/{{nav.subNav}}" *ngFor="let subNav of nav.subNavs | orderBy : 'sort_order'">
              <span class="icon" *ngIf="subNav.icon">
                <i class="{{subNav.icon}}"></i>
              </span>
              <span class="text">
                {{subNav.name}}
              </span>
            </a>
          </div>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="https://store.stacksjar.com/" target="_blank">
          <span class="text">
            Shop
          </span>
          <span class="icon">
            <i class="fas fa-store"></i>
          </span>
        </a>
      </li>
    </ul>
    <ul class="navbar-nav"
      [ngClass]="navigations?.length > 0 ? 'col-lg-2 justify-content-center' : 'col-lg-12 justify-content-end'">
      <li class="nav-item navbar-right" *ngIf="localStorage && localStorage.getItem('isLoggedIn') != 'true'">
        <a class="nav-link write-for-us" routerLink="/contact" [queryParams]="{msg: 'write-for-us'}">
          <span class="text">
            Write For Us
          </span>
          <span class="icon">
            <i class="fas fa-pencil-alt"></i>
          </span>
        </a>
      </li>
      <li CheckAdmin="ADMIN" class="nav-item navbar-right" *ngIf="localStorage && localStorage.getItem('isLoggedIn') != 'true'">
        <a class="nav-link" routerLink="/user/login">Login / Sign Up</a>
      </li>
      <li class="nav-item dropdown" *ngIf="localStorage && localStorage.getItem('isLoggedIn') == 'true'">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
            <img class="user-image d-none d-lg-inline-block" *ngIf="getUserPhoto()"
              src="{{authorImgUrl}}{{getUserPhoto()}}" alt="user-photo">
            <img class="user-image d-none d-lg-inline-block" *ngIf="!getUserPhoto()" src="assets/images/DefaultAvatar.png"
              alt="user-photo">
            {{getUserName()}}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/user/editor/blog">Create post</a>
          <a class="dropdown-item" routerLink="/user/dashboard">Dashboard</a>
          <a class="dropdown-item" href="javascript:;" (click)="logout()">Logout</a>
        </div>
      </li>
    </ul>
  </div>
</nav>