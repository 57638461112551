<div class="container-fluid" #listingstart>
  <div class="row">

    <div class="view-toggler d-none d-lg-flex col-md-12">
      <div class="icon list-icon" [ngClass]="!isGridView ? 'active' : ''" (click)="toggleIconClick(false)">
        <i class="fas fa-th-list" title="List View"></i>
      </div>
      <div class="toggle-button">
        <p-inputSwitch aria-label="view-toggler" [(ngModel)]="isGridView" title="Toggle View" (onChange)="toggleView()" label="view-toggler" name="view-toggler"></p-inputSwitch>
      </div>
      <div class="icon grid-icon" [ngClass]="isGridView ? 'active' : ''" (click)="toggleIconClick(true)">
        <i class="fas fa-th-large" title="Grid View"></i>
      </div>
    </div>


    <div class="feeds-wrapper" [ngClass]="isGridView ? 'col-md-12 grid-view' : 'col-md-8 list-view'" *ngIf="blogs.length > 0 || (loadingBar.value$ | async)">
      <div class="row" *ngIf="blogs && blogs.length > 0 && !(loadingBar.value$ | async)">

        <div class="heading-wrapper col-md-12">
          <h1 class="heading">
            Top articles on {{category ? category : 'Trending web stacks'}}
          </h1>
        </div>
        
        <!-- <ng-container *ngFor="let blog of blogs | paginate: { itemsPerPage: limit, currentPage: page, totalItems: totalBlogs };let bgIdx=index;"> -->
        <ng-container *ngFor="let blog of blogs;let bgIdx=index;">

          <div [ngClass]="isGridView ? 'col-md-6' : 'col-md-12'">
            <!-- (click)="routeToDetails(blog)" -->
            <div class="feed-post" [ngClass]="isGridView ? 'grid-view' : 'list-view'" data-clarity-region="article">
              <a class="article-item" routerLink="/post/{{blog.blog_url}}">
                <div class="img-wrap">
                  <img src="{{serverImgUrl}}{{blog.coverPhoto}}" class="feed-image card-img-top" alt="Blog Photo" loading="lazy"
                    height="400" width="225">
                </div>
                <div class="content-wrap">
                  <h2 class="feed-title">{{blog.title | titlecase}}</h2>
                  <div class="feed-body row">
                    <div *ngIf="blog.author" class="author col-md-6">
                      <span class="photo">
                        <img *ngIf="blog.author.photo" src="{{authorImg}}{{blog.author.photo}}" alt="user-photo" loading="lazy"
                          width="40" height="23">
                        <img *ngIf="!blog.author.photo" src="assets/images/DefaultAvatar.png" alt="user-photo">
                      </span>
                      <label *ngIf="blog.author.name" class="name">{{blog.author.name}}</label>
                    </div>
                    <div class="feed-meta col-md-6">
                      <span class="views">
                        <i class="fas fa-eye" style="opacity: 0;"></i>
                        <!-- {{blog.totalViews ? blog.totalViews : '0'}} -->
                      </span>
                      <span class="date-time" *ngIf="blog.createdAt">
                        {{blog.createdAt | date : longDate}}
                      </span>
                    </div>
                    <label class="category" *ngIf="blog.category">{{blog.category}}</label>
                  </div>
                </div>
              </a>
            </div>
          </div>
  
          <!-- Ads Post -->
          <div [ngClass]="isGridView ? 'col-md-6' : 'col-md-12'" *ngIf="(bgIdx + 1) % 2 == 0 && showAds">
            <div class="feed-post ads-wrapper">
              <div id="ads-block" [data]="'sideBlockResponsive'" Adshandler [lazyLoad]="true"></div>
            </div>
          </div>
        </ng-container>


      </div>

      <!-- Skeleton Loader -->
      <div class="row" *ngIf="loadingBar.value$ | async">
        <div *ngFor="let loader of loaderItems" [ngClass]="isGridView && loaderItems.length > 1  ? 'col-md-4' : 'col-md-6'" class="col-sm-12 feed-loader m-auto">
          <div class="ph-item ">
            <div class="ph-col-12">
              <div class="ph-picture"></div>
              <div class="ph-row">
                <div class="ph-col-12 big"></div>
                <div class="ph-col-12"></div>
                <div class="ph-col-12 empty big"></div>
              </div>
            </div>
            <div class="ph-col-2">
              <div class="ph-avatar"></div>
            </div>
            <div class="ph-col-7">
              <div class="ph-row">
                <div class="ph-col-8 empty"></div>
                <div class="ph-col-4"></div>
                <div class="ph-col-6 empty"></div>
                <div class="ph-col-6"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Pagination -->
      <div class="col-md-12 pagination-wrapper" [ngClass]="blogs && blogs.length > 0 && !(loadingBar.value$ | async) ? 'd-block' : 'd-none'">
        <!-- <pagination-controls (pageChange)="pageChanged($event)" (pageBoundsCorrection)="pageChanged($event)"
          maxSize="4" directionLinks="true" autoHide="false" responsive="false" previousLabel="" nextLabel=""
          screenReaderPaginationLabel="Pagination" screenReaderPageLabel="page"
          screenReaderCurrentLabel="You're on page">
        </pagination-controls> -->
        <p-paginator #paginator [rows]="limit" [first]="lastAccessedPage" pageLinkSize="3" label="pagination" name="pagination" [totalRecords]="totalBlogs" (onPageChange)="pageChanged($event)"></p-paginator>
      </div>

    </div>

    <div class="col-md-4 side-content" *ngIf="!isGridView">
      <div class="row">


        <!-- Trending Post -->
        <!-- <div class="col-md-10 trending-post" *ngIf="trendingBlogs && trendingBlogs.length > 0">
          <label class="head">Trending Posts</label>
          <ul class="trending-wrapper inner-scrollbar">
            <li class="trending-items" *ngFor="let item of trendingBlogs">
              <a class="trending-items-link" routerLink="/post/{{item.blog_url}}">
                <div class="innerContent">
                  <h3 class="type">
                    {{item.title}}
                  </h3>
                  <div class="meta-content">
                    <span class="category col-6">
                      {{item.category}}
                    </span>
                    <span class="time col-6">
                      {{item.createdAt | date : longDate}} 
                      <i class="fas fa-eye"></i>
                      {{item.totalViews}}
                    </span>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div> -->

        <div class="col-md-10 widget-block " *ngIf="affiliateWidgetRightOne">
          <app-affiliatewidget [productData]="affiliateWidgetRightOne"></app-affiliatewidget>
        </div>
          
        <div class="col-md-10 widget-block" *ngIf="(trendingBlogs && trendingBlogs.length > 0) && (recentBlogs && recentBlogs.length > 0)">
          <app-trendingposts (selectedSideContentCallback)="switchSideContent($event)" [selectedSideContentData]="selectedSideContent" [trendingBlogsData]="trendingBlogs" [recentBlogsData]="recentBlogs"></app-trendingposts>
        </div>

        <!-- Ads Post -->
        <div class="col-md-10 widget-block ads-wrapper" *ngIf="showAds">
          <div id="ads-block" [data]="'listingRightOne'" Adshandler [lazyLoad]="false"></div>
      </div>
        
        <div class="col-md-10 widget-block">
          <app-tagswidget></app-tagswidget>
        </div>
        
        <div class="col-md-10 widget-block">
          <app-subscribewidget></app-subscribewidget>
        </div>


        <!-- Ads Post -->
        <!-- <div class="col-md-10 d-none d-md-block trending-post ads-wrapper" *ngIf="showAds">
            <div id="ads-block" [data]="'listingRightTwo'" Adshandler [lazyLoad]="true"></div>
        </div> -->

        <!-- Youtube Post -->
        <!-- <div class="col-md-10 trending-post" *ngIf="showYoutube">
          <label class="head">Video Posts</label>
          <div class="trending-wrapper youtube-video-place embed-responsive embed-responsive-4by3">
            <iframe loading="lazy" width="310" height="200" src="https://www.youtube.com/embed/hVJg2TXflU8?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div> -->
      </div>
    </div>
  </div>

  <!-- Ads Post -->
  <div class="row">
    <div class="col-md-12 ads-wrapper" *ngIf="showAds">
      <div id="ads-block" [data]="'multiplexGridAds'" Adshandler [lazyLoad]="false"></div>
    </div>
  </div>

  <!-- Ads Post -->
  <!-- <div class="row">
    <div class="col-md-12 ads-wrapper" *ngIf="showAds">
      <div id="ads-block" [data]="'adBlock1'" Adshandler [lazyLoad]="true"></div>
    </div>
  </div> -->
  
  <!-- No Data Found -->
  <div class="row nodata-wrapper" *ngIf="!blogs || blogs.length == 0 && !(loadingBar.value$ | async)">
    <div class="col-md-6">
      <div class="img-wrap">
        <img src="assets/images/noposts.png" alt="no-posts">
      </div>
    </div>
    <div class="col-md-6 right-content">
      <div class="content-wrap">
        <h6>Sorry! No data Found.</h6>
        <p>
          Sorry, We were unable to Load Data please Try Again Later
        </p>
        <button class="btn primary" (click)="goToHome()">Go Home</button>
      </div>
    </div>
  </div>
</div>




<!-- Static BlogUI -->
<!-- <div class="feed-post col-md-6">
    <div class="img-wrap">
        <img src="assets/images/iron.jpg" class="feed-image card-img-top" alt="...">
    </div>
    <div class="content-wrap">
        <h5 class="card-title">Bootstrap’s cards provide a flexible and extensible content container with multiple variants and options.</h5>
        <div class="feed-body row">
            <div class="author col-md-6">
                <img src="assets/images/iron.jpg" alt="">
                <label for="">Arif Shaikh</label>
            </div>
            <div class="feed-meta col-md-6">
                <span class="views">
                    <i class="fas fa-eye"></i>
                    107
                </span>
                <span class="date-time">
                    10 July 2019
                </span>
            </div>
            <label class="category">Javascript</label>
        </div>
    </div>
</div> -->
