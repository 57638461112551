import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, isDevMode, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {
  showChatBot: Boolean = false;
  @ViewChild('footer') footer;

  // @HostListener('window:scroll')
  // public onScrollListener(): void {
  //   this.isItInView();
  // }

  constructor(private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: object) {}

  ngOnInit(): void {
  }

  injectFbCHat() {
    const js = this.renderer.createElement('script');
    js.type = 'text/javascript';
    js.id = 'fbChat';
    js.innerHTML = `
    var chatbox = document.getElementById('fb-customer-chat');
    chatbox.setAttribute("page_id", "102365298464455");
    chatbox.setAttribute("attribution", "biz_inbox");
    window.fbAsyncInit = function() {
      FB.init({
        xfbml            : true,
        version          : 'v11.0'
      });
    };
  
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));`;
  
    js.async = true;
    this.renderer.appendChild(document.body, js);
  }

  isItInView() {
    // const adsElement: HTMLElement = document.getElementById(id);
    let adsElement = this.footer.nativeElement;

    if (adsElement && isPlatformBrowser(this.platformId) && !isDevMode()) {
      const bounding = adsElement.getBoundingClientRect();
      if (
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
          bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)
      ) {
          if(!this.showChatBot && !localStorage.getItem('currentUrl').includes('user')) {
            this.showChatBot = true;
            setTimeout(() => {
              this.injectFbCHat();
            }, 3000);
          } 
      }
    }
  } 

  removeChat() {
    this.showChatBot = false;
    var chatJS = document.getElementById('fbChat');
    if(chatJS) {
      this.renderer.removeChild(document.body, chatJS)
    }
  }

  checkPage() {
    if(!localStorage.getItem('currentUrl').includes('user')) {
      return true;
    }
    else {
      if(this.showChatBot) {
        this.removeChat();
      }
      return false;
    }
  }

}
