<div class="container-fluid footer navbar-expand-md">
  <!-- <removed d-none d-md-flex> -->

  <div class="row footer-body" #footer>
    <div class="col-md-1 col-12 meta-wrapper">
      <div class="owner-info row no-gutters">
        <div class="photo">
          <img class="img-fluid" src="assets/images/admin-photo.webp" width="125" height="70" loading="lazy" alt="Arif Shaikh" />
        </div>
      </div>
    </div>
    <div class="col-md-2 col-12 meta-wrapper">
      <div class="owner-info row no-gutters">
        <div class="text">
          <h3>StacksJar</h3>
          <p class="quote">
            Made with 💖 by Arif Shaikh with all Passion. 
          </p>
          <div class="bmc-promo">
            <a href="https://www.buymeacoffee.com/stacksjar" rel="noflollow noopener" target="_blank">
              <img class="img-fluid" src="assets/images/bmc-button.png" alt="buymecoffee" width="200" height="112" loading="lazy">
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3 col-12 tools-links links-container">
      <h3>Tools</h3>
      <div class="links-wrap">
        <a class="footer-link" href="/tools" >Tools</a>
        <a class="footer-link" href="/tools/whiteboard" target="_blank">Whiteboard</a>
        <!-- <a class="footer-link" routerLink="/tools/wordcounter">Word Counter</a> -->
        <a class="footer-link" routerLink="/tools/urlcodec">Url Encoder</a>
        <a class="footer-link" routerLink="/tools/urlcodec">Url Decoder</a>
        <a class="footer-link" routerLink="/apps/quotes">Quotes Generator</a>
        <a class="footer-link" routerLink="/apps/memes">Memes Generator</a>
        <a class="footer-link" routerLink="/tools/backlinks-generator">Backlinks Generator</a>
      </div>
      <!-- <a href="javascript:;" routerLink="/category/css">Css</a><br>
      <a href="javascript:;" routerLink="/category/tips-and-tricks">Tips/Tricks</a><br> -->
    </div>
    <div class="col-md-3 col-12 trending-links links-container">
      <h3>Trending</h3>
      <div class="links-wrap">
        <a class="footer-link" routerLink="/post/make-angular-run-in-offline-mode-pwa">Angular Pwa</a>
        <a class="footer-link" routerLink="/post/tools-for-creating-and-testing-htaccess-file">Htaccess Tools</a>
        <a class="footer-link" routerLink="/post/subscribe-to-keyboard-events-using-event-methods-and-hostlistener">Angular Hostlistener</a>
        <a class="footer-link" routerLink="/post/use-of-font-ligatures-in-vs-code">Font Ligatures Vs Code</a>
        <a class="footer-link" routerLink="/post/use-of-currency-pipe-in-angular">Currency Pipe in Angular</a>
        <a class="footer-link" routerLink="/post/angular-interview-questions-part-1">Angular Interview Questions</a>
      </div>
      <!-- <a href="javascript:;" routerLink="/category/css">Css</a><br>
      <a href="javascript:;" routerLink="/category/tips-and-tricks">Tips/Tricks</a><br> -->
    </div>
    <div class="col-md-3 col-12 quick-links links-container">
      <h3>Quick Links</h3>
      <!-- <a href="javascript:;" routerLink="/category/angular">Angular</a><br>
      <a href="javascript:;" routerLink="/category/javascript">Javascript</a><br>
      <a href="javascript:;" routerLink="/category/essentials">Essentials</a><br> -->
      <div class="links-wrap">
        <a class="footer-link" href="https://stacksjar.com/rss.xml" target="_blank">Rss</a>
        <a class="footer-link" routerLink="/sitemap">Sitemap</a> 
        <a class="footer-link" routerLink="/change-logs">Change Logs</a> 
        <a class="footer-link" routerLink="/contact">Contact Us</a>
        <a class="footer-link" routerLink="/disclaimer">Disclaimer</a>
        <a class="footer-link" routerLink="/privacy-policy">Privacy Policy</a>
        <a class="footer-link" routerLink="/terms-and-conditions">Terms and Conditions</a>
      </div>
    </div>
    <!-- <div class="col-md-3 col-2 d-none d-md-block"> -->
      <!-- <h3>Owner</h3> -->
    <!-- </div> -->
  </div>

  <div class="row footer-mid">
    <div class="footer-social col-md-12 col-12">
      <a class="footer-social-link" href="https://www.facebook.com/stacksjar" target="_blank" rel="noopener">
        <img src="assets/images/facebook.png" alt="facebook-icon" loading="lazy" width="32" height="32">
      </a>
      <!-- <a href="">
        <img src="assets/images/telegram.png" alt="telegram-icon" loading="lazy" width="32" height="32">
      </a> -->
      <a class="footer-social-link" href="https://www.youtube.com/channel/UCqBsG2Gr3SBEABU7i9yL4GA" target="_blank" rel="noopener">
        <img src="assets/images/youtube.png" alt="youtube-icon" loading="lazy" width="32" height="32">
      </a>
      <a class="footer-social-link" href="https://www.twitter.com/stacksjar" target="_blank" rel="noopener">
        <img src="assets/images/twitter.png" alt="twitter-icon" loading="lazy" width="32" height="32">
      </a>
      <a class="footer-social-link" href="https://www.instagram.com/stacksjar" target="_blank" rel="noopener">
        <img src="assets/images/instagram.png" alt="instagram-icon" loading="lazy" width="32" height="32">
      </a>
      <a class="footer-social-link" href="https://www.pinterest.com/stacksjar" target="_blank" rel="noopener">
        <img src="assets/images/pinterest.png" alt="pinterest-icon" loading="lazy" width="32" height="32">
      </a>
      <a class="footer-social-link" href="https://www.linkedin.com/company/stacksjar/" target="_blank" rel="noopener">
        <img src="assets/images/linkedin.png" alt="linkedin-icon" loading="lazy" width="32" height="32">
      </a>
      <a class="footer-social-link" href="https://stacksjar.com/rss.xml" target="_blank">
        <img src="assets/images/rss.png" alt="rss-icon" loading="lazy" width="32" height="32">
      </a>
    </div>
  </div>

  <div class="row">
    <div class="copy-rights col-md-12 col-12">
      <span>
        © 2020-2024 Copyright: StacksJar.com
      </span>
    </div>
  </div>

</div>

<!-- ChatBot -->
<ng-container *ngIf="showChatBot && checkPage()">
  <!-- Messenger Chat Plugin Code -->
  <div id="fb-root"></div>
  
  <!-- Your Chat Plugin code -->
  <div id="fb-customer-chat" class="fb-customerchat">
  </div>
</ng-container>