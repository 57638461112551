<div class="main-container trending-post">
  <div class="heading-wrapper head">
    <button (click)="callSwitchSideContent('trending')"
      [ngClass]="selectedSideContent == 'trending' ? 'active' : ''">Trending Posts</button>
    <button (click)="callSwitchSideContent('recent')"
      [ngClass]="selectedSideContent != 'trending' ? 'active' : ''">Recent
      Posts</button>
  </div>

  <!-- <label class="head">Trending Posts</label> -->

  <!-- trending posts -->
  <ul class="trending-wrapper inner-scrollbar" [ngClass]="selectedSideContent == 'trending' ? 'd-block' : 'd-none'">
    <li class="trending-items" *ngFor="let item of trendingBlogs">
      <a class="trending-items-link" routerLink="/post/{{item.blog_url}}">
        <div class="innerContent">
          <h3 class="type">
            {{item.title}}
          </h3>
          <div class="meta-content">
            <span class="category col-6">
              {{item.category}}
            </span>
            <span class="time col-6">
              <!-- {{item.createdAt | date : longDate}} -->
              <i class="fas fa-eye"></i>
              {{item.totalViews}}
            </span>
          </div>
        </div>
      </a>
    </li>
  </ul>

  <!-- recent posts -->
  <ul class="trending-wrapper inner-scrollbar" [ngClass]="selectedSideContent == 'recent' ? 'd-block' : 'd-none'">
    <li class="trending-items" *ngFor="let item of recentBlogs">
      <a class="recent-items-link" routerLink="/post/{{item.blog_url}}">
        <div class="innerContent">
          <h3 class="type">
            {{item.title}}
          </h3>
          <div class="meta-content">
            <span class="category col-6">
              {{item.category}}
            </span>
            <span class="time col-6">
              {{item.createdAt | date : longDate}}
            </span>
          </div>
        </div>
      </a>
    </li>
  </ul>
</div>
