import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  baseUrl = environment.baseUrl;


  constructor(private http: HttpClient) { }

  getNavs(type) {
    let final = this.baseUrl + 'getNavigation/' + type; 
    return this.http.get(final);
  }
}
