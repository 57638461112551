<div class="container">
  <div class="row">
    <div class="col-md-12 main-wrapper">
      <div class="img-wrap">
        <img src="assets/images/pagenotfound.png" alt="">
      </div>
      <div class="content-wrap">
        <p>Looks like you have Lost
        </p>
        <button class="btn primary" routerLink="/home">Go Home</button>
      </div>
    </div>
  </div>
</div>
