import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { GagtmObject } from './global.interface';

declare var dataLayer;

@Injectable({
  providedIn: 'root'
})

export class GagtmService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

  triggerEvent(data: GagtmObject) {
    if(isPlatformBrowser(this.platformId)) {
      dataLayer = window['dataLayer'] || [];
      dataLayer.push(data);
    }
  }
}
