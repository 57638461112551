<div class="container-fluid pages-container">
  <div class="row">
    <div class="col-md-11 m-auto">
      <div class="row">
        <div class="col-md-12">
          <h1 class="title">Stacksjar Sitemap</h1>
        </div>
        <div class="col-md-8">
          <div class="links-wrapper">
            <div class="item" *ngFor="let item of sitemapData">
              <h2>{{item.name}}</h2>
              <ul>
                <li *ngFor="let link of item.data">
                  <a href="{{link.url}}">
                    {{link.title}}
                  </a>
                </li>
              </ul>
            </div>
            <div class="item">
              <h2>Tools</h2>
              <ul>
                <li>
                  <a href="{{env.domain}}/tools/whiteboard">
                    Whiteboard
                  </a>
                </li>
                <li>
                  <a href="{{env.domain}}/apps/memes">
                    Memes
                  </a>
                </li>
                <li>
                  <a href="{{env.domain}}/apps/quotes">
                    Quotes
                  </a>
                </li>
                <li>
                  <a href="{{env.domain}}/tools/urlcodec">
                    Url Encoder
                  </a>
                </li>
                <li>
                  <a href="{{env.domain}}/tools/urlcodec">
                    Url Decoder
                  </a>
                </li>
                <li>
                  <a href="{{env.domain}}/tools">
                    Free Online Tools
                  </a>
                </li>
              </ul>
            </div>
            <div class="item">
              <h2>Information</h2>
              <ul>
                <li>
                  <a href="{{env.domain}}/contact">
                    Contact
                  </a>
                </li>
                <li>
                  <a href="{{env.domain}}/rss.xml">
                    Rss
                  </a>
                </li>
                <li>
                  <a href="{{env.domain}}/sitemap.xml">
                    Sitemap
                  </a>
                </li>
                <li>
                  <a href="{{env.domain}}/change-logs">
                    Change Logs
                  </a>
                </li>
                <li>
                  <a href="{{env.domain}}/disclaimer">
                    Disclaimer
                  </a>
                </li>
                <li>
                  <a href="{{env.domain}}/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="{{env.domain}}/terms-and-conditions">
                    Terms and Conditions
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <!-- Ads Post -->
          <div class="ads-wrapper block-one">
            <div id="ads-block" [data]="'multiplexGridAds'" Adshandler [lazyLoad]="false"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>