import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  disableConsoleLogs() {
    if(environment.disableLogs) {
      // stop console log in production mode.
      console.log = function() {};      
      console.error = function() {};      
      console.warn = function() {}; 
    }
  }
}
