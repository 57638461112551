import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-affiliatewidget',
  templateUrl: './affiliatewidget.component.html',
  styleUrl: './affiliatewidget.component.less'
})
export class AffiliatewidgetComponent {
  @Input('productData') productData: any;


}
