import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { NgxPaginationModule } from 'ngx-pagination';
import { DropdownModule } from 'primeng/dropdown';
import { SanitizehtmlPipe } from '../pipes/sanitizehtml.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttprequestInterceptor } from '../services/httprequest.interceptor';
import { CheckadminDirective } from '../services/checkadmin.directive';
import { CardanimateDirective } from '../services/cardanimate.directive';
import { OrderbyPipe } from '../services/orderby.pipe';
import { AdshandlerDirective } from '../services/adshandler.directive';
import { PaginatorModule } from 'primeng/paginator';
import { ColorPickerModule } from 'primeng/colorpicker';
import { AdsenseComponent } from '../adsense/adsense.component';
import { WidgetsModule } from '../widgets/widgets.module';


@NgModule({
  declarations: [
    SanitizehtmlPipe,
    CheckadminDirective,
    CardanimateDirective,
    OrderbyPipe,
    AdshandlerDirective,
    AdsenseComponent
  ],
  imports: [
    CommonModule,
    // NgxPaginationModule,
    DropdownModule,
    ColorPickerModule,
    FormsModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    HttpClientModule,
    PaginatorModule,
    ReactiveFormsModule,
    WidgetsModule
  ],
  exports: [
    CommonModule,
    // NgxPaginationModule,
    DropdownModule,
    ColorPickerModule,    
    FormsModule,
    LoadingBarHttpClientModule,
    LoadingBarModule,
    HttpClientModule,
    SanitizehtmlPipe,
    OrderbyPipe,
    HttpClientModule,
    PaginatorModule,
    CheckadminDirective,
    CardanimateDirective,
    AdshandlerDirective,
    ReactiveFormsModule,
    AdsenseComponent,
    WidgetsModule
  ],
  providers: [
    SanitizehtmlPipe,
    OrderbyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttprequestInterceptor, multi: true }
  ]
})

export class SharedmoduleModule { }
