export const ads = {
  adBlock1: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": 2890409459,
    "data-ad-format": "auto",
    "data-full-width-responsive": true
  },
  associateProd: {
    "style": "width:120px;height:240px;",
    "marginwidth": 0,
    "marginheight": 0,
    "frameborder": 0,
    "src": "//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=awkshaikh-21&language=en_IN&marketplace=amazon&region=IN&placement=B092DHTXH2&asins=B092DHTXH2&linkId=ba3241c67ca930b27c1a7070122ee4aa&show_border=true&link_opens_in_new_window=true"
  },
  multiplexGridAds: {
    "display": "block",
    "data-ad-format": "autorelaxed",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": "5352295539"
  },
  sideBlockResponsive: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": 2699313087,
    "data-ad-format": "auto",
    "data-full-width-responsive": true
  },
  betweenDetailsResponsive: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": 1238606341,
    "data-ad-format": "auto",
    "data-full-width-responsive": true
  },
  betweenSnippetsDetailsResponsive: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": "5688711539",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true"
  },
  banner: {
    "display": "inline-block",
    "width": "468px",
    "height": "60px",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": 7846902792,
  },
  leaderboard: {
    "display": "inline-block",
    "width": "728px",
    "height": "90px",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": 8450302369,
  },
  smallSquare: {
    "display": "inline-block",
    "width": "200px",
    "height": "200px",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": 4005211771,
  },
  mediumRectangle: {
    "display": "inline-block",
    "width": "300px",
    "height": "250px",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": 8136028472,
  },
  largeRectangle: {
    "display": "inline-block",
    "width": "336px",
    "height": "280px",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": 2076465706,
  },
  square: {
    "display": "inline-block",
    "width": "250px",
    "height": "250px",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": 9295398607,
  },
  snippetsListRightOne: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": "4058589225",
    "data-ad-format": "rectangle",
    "data-full-width-responsive": "true",
  },
  snippetsListLeftOne: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": "6493180876",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true"
  },
  snippetsDetailsLeftOne: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": "9119344214",
    "data-ad-format": "rectangle",
    "data-full-width-responsive": "true",
  },
  snippetsDetailsRightTwo: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": "9749899606",
    "data-ad-format": "rectangle, vertical, auto",
    "data-full-width-responsive": "true"
  },
  snippetsDetailsRightOne: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": "5765426712",
    "data-ad-format": "rectangle",
    "data-full-width-responsive": "true"
  },
  listingRightOne: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": "6523356065",
    "data-ad-format": "rectangle",
    "data-full-width-responsive": "true"
  },
  detailsRightOne: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": "5563242587",
    "data-ad-format": "rectangle",
    "data-full-width-responsive": "true"
  },
  detailsLeftOne: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": "9484561328",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true"
  },
  listingRightTwo: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": "6289074858",
    "data-ad-format": "auto",
    "data-full-width-responsive": "true"
  },
  detailsLeftTwo: {
    "display": "block",
    "data-ad-client":"ca-pub-2297003413569812",
    "data-ad-slot":"4784421491",
    "data-ad-format":"auto",
    "data-full-width-responsive":"true"
  },
  detailsRightTwo: {
    "display": "block",
    "data-ad-client":"ca-pub-2297003413569812",
    "data-ad-slot":"7410584836",
    "data-ad-format":"auto",
    "data-full-width-responsive":"true"
  },
  memesPage: {
    "display": "block",
    "data-ad-client": "ca-pub-2297003413569812",
    "data-ad-slot": "6556246701",
    "data-ad-format": "vertical, auto",
    "data-full-width-responsive" :"true",
  },
  //   associateProdIframe: `<iframe id="amazon-associate" style="width: 350px;max-width: 350px;height: 350px;max-height: 400px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=awkshaikh-21&language=en_IN&marketplace=amazon&region=IN&placement=B092DHTXH2&asins=B092DHTXH2&linkId=ba3241c67ca930b27c1a7070122ee4aa&show_border=false&link_opens_in_new_window=true"></iframe>`
  // associateProdIframe: `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=awkshaikh-21&language=en_IN&marketplace=amazon&region=IN&placement=B092DHTXH2&asins=B092DHTXH2&linkId=5dd3d753adc310d7ddb9dc16e6491126&show_border=false&link_opens_in_new_window=true"></iframe>`,
  associateProdIframe: `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=awkshaikh-21&language=en_IN&marketplace=amazon&region=IN&placement=B08H8Q5KLK&asins=B08H8Q5KLK&linkId=b0a6d2dce2f11424b6a9dfbccfd66747&show_border=false&link_opens_in_new_window=true"></iframe>`,
  listingAssociateProd: `<iframe style="width:120px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-in.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=IN&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=awkshaikh-21&language=en_IN&marketplace=amazon&region=IN&placement=B08L13YPGZ&asins=B08L13YPGZ&linkId=34315d43068af98559d51461c720b49c&show_border=false&link_opens_in_new_window=true"></iframe>`,
  search: `<script async src="https://cse.google.com/cse.js?cx=e859b1dd35e435bd8"></script>
  <div class="gcse-search"></div>`
}

export const adsScript = {
  adBlock1: `<ins class="adsbygoogle"
  style="display:block"
  data-ad-client="ca-pub-2297003413569812"
  data-ad-slot="2890409459"
  data-ad-format="auto"
  data-full-width-responsive="true"></ins>
  <script>
  (adsbygoogle = window.adsbygoogle || []).push({});
  </script>`,
  square: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- Square (250 x 250) -->
<ins class="adsbygoogle"
style="display:inline-block;width:250px;height:250px"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="9295398607"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
  largeRectangle: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- Large Rectangle (336 x 280) -->
<ins class="adsbygoogle"
style="display:inline-block;width:336px;height:280px"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="2076465706"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
  mediumRectangle: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- Medium Rectangle (300 x 250) -->
<ins class="adsbygoogle"
style="display:inline-block;width:300px;height:250px"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="8136028472"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
  smallSquare: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- Small Square (200 x 200) -->
<ins class="adsbygoogle"
style="display:inline-block;width:200px;height:200px"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="4005211771"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
  leaderboard: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- Leaderboard (728 x 90) -->
<ins class="adsbygoogle"
style="display:inline-block;width:728px;height:90px"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="8450302369"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
  banner: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- Banner (468 x 60) -->
<ins class="adsbygoogle"
style="display:inline-block;width:468px;height:60px"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="7846902792"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
  sideBlockResponsive: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- SideBlockResponsive -->
<ins class="adsbygoogle"
style="display:block"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="2699313087"
data-ad-format="auto"
data-full-width-responsive="true"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
  snippetsDetailsRightOne: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
  crossorigin="anonymous"></script>
<!-- snippetsdetails-right-three -->
<ins class="adsbygoogle"
  style="display:block"
  data-ad-client="ca-pub-2297003413569812"
  data-ad-slot="5765426712"
  data-ad-format="auto"
  data-full-width-responsive="true"></ins>
<script>
  (adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
  snippetsDetailsRightTwo: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- snippetsdetails-right-two -->
<ins class="adsbygoogle"
style="display:block"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="9749899606"
data-ad-format="auto"
data-full-width-responsive="true"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>
`,
snippetsListLeftOne: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- snippetsListLeftOne -->
<ins class="adsbygoogle"
style="display:block"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="6493180876"
data-ad-format="auto"
data-full-width-responsive="true"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>
`,
snippetsDetailsLeftOne: `
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
     crossorigin="anonymous"></script>
<!-- snippetsdetails-right-one -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-2297003413569812"
     data-ad-slot="9119344214"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
`,
  snippetsListRightOne: `
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
     crossorigin="anonymous"></script>
<!-- snippetslist-right-one -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-2297003413569812"
     data-ad-slot="4058589225"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>
`,
  listingRightOne: `
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
     crossorigin="anonymous"></script>
<!-- listing-right-one -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-2297003413569812"
     data-ad-slot="6523356065"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
  detailsRightOne: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- details-right-one -->
<ins class="adsbygoogle"
style="display:block"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="5563242587"
data-ad-format="auto"
data-full-width-responsive="true"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>
`,
  detailsLeftOne: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- details-left-one -->
<ins class="adsbygoogle"
style="display:block"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="9484561328"
data-ad-format="auto"
data-full-width-responsive="true"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
  listingRightTwo: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- listing-right-two -->
<ins class="adsbygoogle"
style="display:block"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="6289074858"
data-ad-format="auto"
data-full-width-responsive="true"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
  detailsRightTwo: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- details-right-two -->
<ins class="adsbygoogle"
style="display:block"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="7410584836"
data-ad-format="auto"
data-full-width-responsive="true"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
  detailsLeftTwo: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- details-left-two -->
<ins class="adsbygoogle"
style="display:block"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="4784421491"
data-ad-format="auto"
data-full-width-responsive="true"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
memesPage: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
crossorigin="anonymous"></script>
<!-- memes-page -->
<ins class="adsbygoogle"
style="display:block"
data-ad-client="ca-pub-2297003413569812"
data-ad-slot="6556246701"
data-ad-format="auto"
data-full-width-responsive="true"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`,
betweenSnippetsDetailsResponsive: `
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2297003413569812"
     crossorigin="anonymous"></script>
<!-- BetweenSnippetsDetailsResponsive -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-2297003413569812"
     data-ad-slot="5688711539"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>`
}