import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { BlogserviceService } from '../services/blogservice.service';
import { SetmetadataService } from '../services/setmetadata.service';

@Component({
  selector: 'app-home2',
  templateUrl: './home2.component.html',
  styleUrls: ['./home2.component.less']
})
export class Home2Component implements OnInit {
  svgUrl = 'assets/images/Slanted-Gradient.svg';
  factsData = [{
    id: 1,
    title: "True Responsiveness",
    body: "My layouts will work on any device big or small",
    icon: "fas fa-magic"
  },
  {
    id: 2,
    title: "Fast",
    body: "Fast Load Times and lag free interaction is my highest priority",
    icon: "fas fa-tachometer-alt"
  },
  {
    id: 3,
    title: "Intuitive",
    body: "Strong presence for easy to use intuitive UI/UX",
    icon: "far fa-lightbulb"
  },
  {
    id: 4,
    title: "Dynamic",
    body: "Websites dont have to be static. I love making pages come to life",
    icon: "fas fa-rocket"
  }
];
  emailId: string;

  constructor(private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object, private metadataService: SetmetadataService, private blogService: BlogserviceService) { }

  ngOnInit(): void {
    this.setMetaTags();
    this.setLinkTag();
  }

  setMetaTags() {
    let data = {
      title: 'Stacksjar.com',
      description: 'High Quality Practical Resources to skill up your Web Development Career and Boost your Productivity. Get latest articles on Angular 10, NodeJS, MongoDB, Javascript, Typescript, Css, Less and Useful Development tips and tricks.',
      keywords: 'Angular, Javascript, Less, Full Stack, StacksJar, Php, Laravel, Node, MongoDB',
      robots: 'index, follow'
    } 
    this.metadataService.setMetaTags(data);
  }

  subscribeNewsletter(email) {
    email.isSubmitted = true;
    const emailCheck = this.checkEmail();
    if(this.emailId && emailCheck) {
      email.loading = true;
      let data = {
        'email': this.emailId
      } 
      
      this.blogService.subscribeToNewsletter(data).subscribe(res => {
        if(res) {
          console.log(res);
          if(res['statusCode'] == 200) {
            console.log(this.emailId)
            email.loading = false;
            email.completed = true;
            setTimeout(() => {
              email.reset();
              email.completed = false;
              email.isSubmitted = false;
              email.loading = false;
            }, 1500);
          }
        }
      },
      err => {
        if(err) {
          email.completed = false;
          email.loading = false;
        }
      })
    }
    else {
      email.isInvalid = true;
    } 
  }

  emailInputs(email) {
    if(email.isSubmitted) {
    const res = this.checkEmail();  
      if(res) {
        email.isInvalid = false;
      }
      else {
        email.isInvalid = true;
      }
    }
  }

  checkEmail() {
    const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(String(this.emailId).toLowerCase());
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      const item: any = document.querySelector('link[rel="canonical"]');
      
      if(item) {
        document.head.removeChild(item);
      }
    }
  }

  setLinkTag() {
    const item: any = this.document.querySelector('link[rel="canonical"]');

    if(item) {
      item.href = `https://stacksjar.com`;
    }
    else {
      const link = this.renderer.createElement('link');
      link.rel = 'canonical';
      link.href = `https://stacksjar.com`;
      
      this.renderer.appendChild(this.document.head, link);
    }
  }

}
