import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SetmetadataService {
  title: String = "Stacksjar.com";
  keywords: String = "Learn Web Development, Web Development Tools, Web Development Jobs, Angular, Javascript, Less, Full Stack, StacksJar, Php, Laravel, Node, MongoDB,";
  description: String = "High Quality Practical Resources to skill up your Web Development Career and Boost your Productivity. Get latest articles on Angular 10, NodeJS, MongoDB, Javascript, Typescript, Css, Less and Useful Development tips and tricks.";
  url: String = "https://stacksjar.com";
  image: String = "https://stacksjar.com/assets/images/logo.png";
  cover: String = "https://stacksjar.com/assets/images/cover.jpg";
  robots: String = "index, follow";
  fillerWords: any[] = ['of', 'or', 'in', 'on', 'as', 'the', 'to', 'for', 'learn', 'post', 'at', 'an', 'am', 'is', 'are', 'you', 'will', 'how', 'use', 'also', 'your', 'and', 'the', 'files', 'make', 'your', 'a', 'so', 'it', 'if', 'user', 'goes', 'this'];

  constructor(private metaData: Meta, private titleData: Title) { }

  setMetaTags(data) {

    data.title = data.title ? `${data.title} | Stacksjar` : '';

    this.titleData.setTitle(data.title ? data.title : this.title);
    this.metaData.updateTag({name: 'keywords', content: data.keywords ? data.keywords : this.keywords});
    this.metaData.updateTag({name: 'description', content: data.description ? data.description : this.description});

    // Set Keywords
    // if(data.url && data.description && (data.url.includes('post') || data.url.includes('snippets'))) {
    //   let generatedKeywords:any = this.findKeywordsFromDescription(data.description);
    //   this.metaData.updateTag({ name: 'keywords', content: generatedKeywords ? generatedKeywords : this.keywords });
    // }
    // else {
    //   this.metaData.updateTag({ name: 'keywords', content: data.keywords ? data.keywords : this.keywords });
    // }


    // open graph meta tags for facebook etc
    this.metaData.updateTag({ property: 'og:title', content: data.title ? data.title : this.title });
    this.metaData.updateTag({ property: 'og:url', content: data.url ? data.url : this.url });
    this.metaData.updateTag({ property: 'og:image', content: data.image ? data.image : this.cover });
    this.metaData.updateTag({ property: 'og:description', content: data.description ? data.description : this.description });


    // meta tags for twitter
    this.metaData.updateTag({ name: 'twitter:card', content: 'summary' });
    // this.metaData.updateTag({name: 'twitter:site', content: data.title});
    this.metaData.updateTag({ name: 'twitter:title', content: data.title ? data.title : this.title });
    this.metaData.updateTag({ name: 'twitter:image', content: data.image ? data.image : this.cover });
    this.metaData.updateTag({ name: 'twitter:description', content: data.description ? data.description : this.description });

    // this.metaData.updateTag({name: 'og:type', content: 'article'});
    // this.metaData.updateTag({name: 'robots', content: data.robots ? data.robots : this.robots});
  }

  findKeywordsFromDescription(description) {
    let words = description.replace(',', '').replace('.', '').split(' ');
    
    let keywords = new Set([]);
    let repeatedKeywords = new Set([]);

    let ignoreWords = new Set(this.fillerWords);

    words.filter((item) => {
      if (!keywords.has(item)) {
        keywords.add(item);
      }
      else {
        if(!ignoreWords.has(item)) {
          repeatedKeywords.add(item);
        }
      }
    });

    return [...repeatedKeywords].join();
  }
}
